<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#313189" />
      <div v-else>
      <!-- header section -->
      <section class="header header--about">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-10">
              <div v-if="header_title.length > 0" v-html="$prismic.asHtml(this.header_title)" class="header__title"></div>
              <div v-if="header_text.length > 0" v-html="$prismic.asHtml(this.header_text)" class="header__text"></div>
            </div>
          </div>
        </div>
      </section>
      <!-- about section -->
      <section data-aos="fade-up" class="about">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6">
              <img class="img-fluid about__image" :src="this.about_image.url" :alt="this.about_image.alt" />
            </div>
            <div class="col-12 col-lg-6 position-relative">
              <div v-if="about_text.length > 0" v-html="$prismic.asHtml(this.about_text)" class="about__text"></div>
            </div>
          </div>
        </div>
      </section>
      <!-- mission section -->
      <section @mouseover="playVideo" data-aos="fade-up" class="mission">
        <div class="container">
          <h2 data-aos="fade-up" v-if="mission_title.length > 0" class="main-title">{{ $prismic.richTextAsPlain(this.mission_title) }}</h2>
          <div class="row position-relative video">
            <div class="col-12 col-lg-4">
              <div v-if="mission_text.length > 0" v-html="$prismic.asHtml(this.mission_text)" class="mission__text"></div>
            </div>
            <div class="col-12 col-lg-8 mission__box">
              <img class="img-fluid mission__image" :src="this.mission_image.url" :alt="this.mission_image.alt" />
              <video class="mission__video" muted loop>
                <source :src="require('@/assets/video/about_video_0.mp4')" type="video/mp4">
              </video>
            </div>
          </div>
        </div>
      </section>
      <!-- business section -->
      <section data-aos="fade-up" class="business">
        <div class="container">
          <h2 data-aos="fade-up" v-if="business_title.length > 0" class="main-title">{{ $prismic.richTextAsPlain(this.business_title) }}</h2>
          <div class="row">
            <div class="col-12 col-lg-5">
              <img class="img-fluid business__image" :src="this.business_image.url" :alt="this.business_image.alt" />
            </div>
            <div class="col-12 col-lg-7 position-relative">
              <div v-if="business_text.length > 0" v-html="$prismic.asHtml(this.business_text)" class="business__text"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'AboutPage',
  components: {
    Spinner
  },
  data: () => {
    return {
      cmsContentPending: false,
      header_title: null,
      header_text: null,
      about_image: null,
      about_text: null,
      mission_title: null,
      mission_image: null,
      mission_text: null,
      business_title: null,
      business_image: null,
      business_text: null
    }
  },
  methods: {
    async getAboutContent () {
      await this.$prismic.client.getSingle('aboutpage', { lang: this.$i18n.lang })
        .then((document) => {
          // header section
          this.header_title = document.data.header_title
          this.header_text = document.data.header_text
          // about section
          this.about_image = document.data.about_image
          this.about_text = document.data.about_text
          // mission section
          this.mission_title = document.data.mission_title
          this.mission_image = document.data.mission_image
          this.mission_text = document.data.mission_text
          // business section
          this.business_title = document.data.business_title
          this.business_image = document.data.business_image
          this.business_text = document.data.business_text
          this.cmsContentPending = true
        })
    },
    playVideo () {
      const videos = document.querySelectorAll('.video')
      videos.forEach(video => {
        video.addEventListener('mouseover', function () {
          this.getElementsByTagName('video')[0].play()
        })
        video.addEventListener('mouseout', function () {
          this.getElementsByTagName('video')[0].pause()
        })
        video.addEventListener('touchstart', function () {
          this.getElementsByTagName('video')[0].play()
        })
        video.addEventListener('touchend', function () {
          this.getElementsByTagName('video')[0].pause()
        })
      })
    }
  },
  created () {
    this.getAboutContent()
  },
  watch: {
    '$i18n.locale': function (newValue) {
      this.getAboutContent()
    }
  }
}
</script>

<style lang="scss">
  @import "./AboutPage.scss";
</style>
